<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader header-text="Simple Campaign">
      <template #subheader>
        Get started quickly with a Simple campaign. If you’d like to customize your campaign
        further, switch to our Automation campaign builder
      </template>
    </PageHeader>

    <v-alert
      v-if="accountHasTopicOptOut"
      color="#f8e8ad"
      icon="mdi-alert"
      class="mt-3 mb-6"
    >
      Attention: Your account is configured with specific topic/segment opt-ins.
      Please exercise caution when selecting segments for your campaigns.
      Ensure that the segments you choose align with your current opt-ins to avoid targeting errors and to maintain compliance with audience preferences.
    </v-alert>

    <v-row>
      <v-col cols="12" sm="4" class="py-0">
        <CustomTextInput
          v-model="name"
          header-class="field__header"
          header="Campaign Name"
          required
        />
      </v-col>
    </v-row>
    <template v-if="!readOnly">
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <div style="padding:12px;">
          <v-switch
            v-model="activateSplitTesting"
            class="custom-switch"
            inset
            label="Activate A/B Testing"
            @change="value => clearABTestingData(value)"
          />
        </div>
      </v-row>
      <v-row v-else>
        <div style="padding:12px;">
          <div class="ABTesting-label">
            Activate A/B Testing
          </div>
          <v-switch
            v-model="activateSplitTesting"
            class="custom-switch"
            inset
            @change="value => clearABTestingData(value)"
          />
        </div>
      </v-row>
    </template>

    <v-row class="py-0">
      <v-col cols="12" sm="4" class="py-0">
        <CustomDropdown
          v-model="campaignConfig.segmentId"
          autocomplete
          :readonly="readOnly"
          header-class="field__header"
          header="To Who"
          short
          multiple
          required
          item-text="text"
          subheader="Who are you sending this campaign to?"
          placeholder="To Who"
          :items="segments_to_select"
        />
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <CustomDropdown
          v-model="campaignConfig.fromLineId"
          :readonly="readOnly"
          header-class="field__header"
          header="From Who"
          required
          item-text="text"
          subheader="Who is sending this campaign?"
          placeholder="From Who"
          :items="fromLineOptions"
        />
      </v-col>
      <v-col
        v-if="campaignConfig.fromLineId === 'SEND_FROM_REP'"
        cols="12"
        sm="4"
        class="py-0"
      >
        <CustomDropdown
          v-model="campaignConfig.backupFromLineId"
          :readonly="readOnly"
          header-class="field__header"
          header="From Line for contacts without a rep"
          required
          item-text="text"
          subheader="Contacts without a rep will receive the email from this option"
          placeholder="From Who"
          :items="backupFromLineOptions"
        />
      </v-col>
    </v-row>

    <v-divider class="mt-3 mb-6" />

    <SimpleCampaignDetails
      :is-readonly="readOnly"
      :type="type"
      :campaign_config.sync="campaignConfig"
      :campaign="campaign"
      :selected_contact_type_key1.sync="selected_contact_type_key1"
      :selected_contact_type_key2.sync="selected_contact_type_key2"
      :selected_contact_type_key3.sync="selected_contact_type_key3"
      :selected_existing_campaign.sync="selectedEmailCreative"
      :activate_testing="activateSplitTesting"
    />

    <v-divider class="mt-3 mb-6" />

    <v-row class="mb-5">
      <v-col cols="12" sm="4" class="py-0">
        <CustomDateAndTimePicker
          :readonly="readOnly"
          :time="deliveryTime"
          :date="deliveryDate"
          :allowed_minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
          header="Delivery Date"
          header-class="field__header"
          placeholder="Pick the delivery date"
          required
          @date-input="newDate => (deliveryDate = newDate)"
          @time-input="newTime => (deliveryTime = newTime)"
        />
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <TimezoneDropdown
          v-model="timeZone"
          :readonly="readOnly"
          header-class="field__header"
          required
        />
      </v-col>
    </v-row>

    <v-divider class="my-4" />

    <v-row class="mb-5">
      <v-col cols="12" class="d-flex justify-end">
        <template v-if="!readOnly">
          <v-btn
            class="custom-button custom-button--gray px-7 mr-3"
            height="34px"
            depressed
            @click="saveCampaign('draft')"
          >
            Save Draft
          </v-btn>
          <v-btn
            class="custom-button custom-button--blue px-7"
            height="34px"
            depressed
            @click="saveCampaign('scheduled')"
          >
            Schedule Now
          </v-btn>
        </template>
        <v-btn
          v-else
          class="custom-button custom-button--blue"
          height="34px"
          depressed
          @click="saveCampaign('live')"
        >
          Update Name
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import SimpleCampaignDetails from "@/views/Campaigns/components/SimpleCampaignDetails";
import CustomDateAndTimePicker from "@/sharedComponents/CustomDateAndTimePicker";
import TimezoneDropdown from "@/sharedComponents/TimezoneDropdown";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "SimpleCampaignView",
  metaInfo: {
    title: 'Simple Campaign',
  },
  components: {
    PageHeader,
    CustomTextInput,
    CustomDropdown,
    SimpleCampaignDetails,
    TimezoneDropdown,
    CustomDateAndTimePicker,
  },
  props: {
    id: {
      default: () => false,
    },
  },
  data() {
    return {
      segments: [],
      fromLines: [],
      emailCreatives: [],
      activateSplitTesting: false,
      selected_contact_type_key1: null,
      selected_contact_type_key2: null,
      selected_contact_type_key3: null,
      selectedEmailCreative: {},
      campaign: null,
      name: '',
      type: 'simple', // can also be "split-test" if selected
      campaignConfig: {
        testSizePercent: 15,
        segmentId: undefined,
        winnerMetric: undefined,
        sendWinningEmail: undefined,
        analysisPhaseDelay: undefined,
        /*phase: "test", // this is shouldn't be handled by frontend, just pass it as is*/
        type: 'AB',
        testType: 'subject',
        fromLineId: undefined,
        backupFromLineId: undefined,
        creativeId: undefined,
        subject: undefined,
        previewText: undefined,
        tests: {
          A: {
            creativeId: undefined,
            subject: undefined,
            previewText: undefined,
          },
          B: {
            creativeId: undefined,
            subject: undefined,
            previewText: undefined,
          },
          C: {
            creativeId: undefined,
            subject: undefined,
            previewText: undefined,
          },
        },
      },
      timeZone: null,
      deliveryDate: null,
      deliveryTime: null,
    };
  },
  computed: {
    accountHasTopicOptOut() {
      return this.$store.getters["user/accountHasTopicOptOut"];
    },
    segments_to_select: {
      get() {
        return this.segments.map(i => ({
          text: `${i.id} - ${i.name}`,
          value: i.id,
        }));
      },
    },
    fromLineOptions() {
      return this.fromLines.map(i => ({
        text: i.fromEmail ? `${i.fromName}<${i.fromEmail}>` : `${i.fromName}`,
        value: i.id,
      }));
    },
    backupFromLineOptions() {
      return this.fromLineOptions.filter(i => i.value !== 'SEND_FROM_REP');
    },
    readOnly() {
      return ['preparing','live','completed','canceled'].includes(this.campaign?.status)
    }
  },
  async created() {
    await this.loadFromLines();
    await this.loadEmailCreatives();

    if (this.id) {
      await this.loadCampaign(this.id);
    }

    await this.loadSegments();
  },
  methods: {
    clearABTestingData(value) {
      if (this.type === 'simple') {
        this.type = 'split-test';
        this.campaignConfig.testType = 'subject';
        this.campaignConfig.type = 'AB';
        this.campaignConfig.winnerMetric = 'open';
      } else {
        this.type = 'simple';
        delete this.campaignConfig.testType;
        delete this.campaignConfig.type;
        delete this.campaignConfig.winnerMetric;
      }
      if (!value) {
        this.selected_contact_type_key1 = null;
        this.selected_contact_type_key2 = null;
        this.selected_contact_type_key3 = null;
        this.selectedEmailCreative = {};
      }
    },
    get_segments_nodes(email_uuid) {
      if (!this.campaignConfig?.segmentId) {
        return {};
      }

      const applied_segments = this.segments.filter(
        i => this.campaignConfig.segmentId.indexOf(i.id) !== -1
      );

      let segments = {};
      applied_segments.forEach(i => {
        let segment_uuid = uuidv4();
        let transition_uuid = uuidv4();
        segments[segment_uuid] = {
          type: 'Trigger',
          nodeType: 'Segment',
          name: i.name,
          position: { x: 50, y: 50 },
          config: {
            name: i.name,
            segmentId: i.id,
            condition: 'all',
          },
          transitions: {},
        };
        segments[segment_uuid].transitions[transition_uuid] = {
          next: email_uuid,
          event: 'entered',
        };
      });
      return segments;
    },
    getEmailNode() {
      let config = {};

      if (this.type === 'simple') {
        config = {
          name: this.getEmailNodeName(),
          creativeId: this.campaignConfig.creativeId,
          fromLineId: this.campaignConfig.fromLineId,
          backupFromLineId: this.campaignConfig.fromLineId === 'SEND_FROM_REP'
            ? this.campaignConfig.backupFromLineId
            : null,
          subject: this.campaignConfig.subject,
          previewText: this.campaignConfig.previewText,
          canReceiveMultiple: true,
        };
      } else if (this.type === 'split-test') {
        config = {
          winnerMetric: this.campaignConfig.winnerMetric,
          sendWinningEmail: this.campaignConfig.sendWinningEmail,
          analysisPhaseDelay: this.campaignConfig.analysisPhaseDelay,
          phase: 'test',
          type: this.campaignConfig.type || 'AB',
          testType: this.campaignConfig.testType || 'subject',
          testSizePercent: this.campaignConfig.testSizePercent,
          fromLineId: this.campaignConfig.fromLineId,
          backupFromLineId: this.campaignConfig.fromLineId === 'SEND_FROM_REP'
            ? this.campaignConfig.backupFromLineId
            : null,
        };

        if (this.campaignConfig.testType === 'content') {
          config.subject = this.campaignConfig.subject;
          config.previewText = this.campaignConfig.previewText;
        }

        if (this.campaignConfig.type === 'AB') {
          config.tests = {
            A: { ...this.campaignConfig.tests.A },
            B: { ...this.campaignConfig.tests.B },
          };

          if (this.campaignConfig.testType === 'subject') {
            config.tests.A.creativeId = this.selectedEmailCreative?.id;
            config.tests.B.creativeId = this.selectedEmailCreative?.id;
          } else if (this.campaignConfig.testType === 'content') {
            if (this.selected_contact_type_key1?.id) {
              config.tests.A.creativeId = this.selected_contact_type_key1?.id;
            }
            if (this.selected_contact_type_key2?.id) {
              config.tests.B.creativeId = this.selected_contact_type_key2?.id;
            }
          }
        } else if (this.campaignConfig.type === 'ABC') {
          config.tests = {
            A: { ...this.campaignConfig.tests.A },
            B: { ...this.campaignConfig.tests.B },
            C: { ...this.campaignConfig.tests.C },
          };
          if (this.campaignConfig.testType === 'subject') {
            config.tests.A.creativeId = this.selectedEmailCreative?.id;
            config.tests.B.creativeId = this.selectedEmailCreative?.id;
            config.tests.C.creativeId = this.selectedEmailCreative?.id;
          } else if (this.campaignConfig.testType === 'content') {
            if (this.selected_contact_type_key1?.id) {
              config.tests.A.creativeId = this.selected_contact_type_key1?.id;
            }
            if (this.selected_contact_type_key2?.id) {
              config.tests.B.creativeId = this.selected_contact_type_key2?.id;
            }
            if (this.selected_contact_type_key3?.id) {
              config.tests.C.creativeId = this.selected_contact_type_key3?.id;
            }
          }
        }
      }

      let emails = {};
      emails[uuidv4()] = {
        type: 'Action',
        nodeType: this.type === 'split-test' ? 'SendSplitTestEmail' : 'SendEmail',
        name: this.getEmailNodeName(),
        position: { x: 150, y: 50 },
        config: { ...config }
      };

      return emails;
    },
    getEmailNodeName() {
      if (this.type !== 'split-test') {
        return this.selectedEmailCreative?.name ? this.selectedEmailCreative.name : '';
      }

      return this.campaignConfig.type === 'AB' ? 'A/B Split Test' : 'A/B/C Split Test';
    },
    async saveCampaign(status) {
      let email = this.getEmailNode();
      Object.values(email)[0].config.creativeId = this.selectedEmailCreative?.id;
      let segments = this.get_segments_nodes(Object.keys(email)[0]);

      let startDate = null;

      if (this.deliveryDate && this.deliveryTime) {
        startDate = `${this.deliveryDate} ${this.deliveryTime}`;
      }

      let body = {
        name: this.name,
        type: this.type,
        status,
        startDate,
        timezoneOffset: this.timeZone,
        draftConfiguration: {
          nodes: {
            ...segments,
            ...email,
          },
        },
      };

      if (this.id) {
        if (status === 'live') {
          await this.$rest.campaign.put_resource(this.id, {
            name: this.name,
          });
        } else {
          await this.$rest.campaign.put_resource(this.id, body);
        }
      } else {
        await this.$rest.campaign.post_resource({
          name: this.name,
          type: this.type,
          status,
          startDate,
          timeZoneOffset: this.timeZone,
          config: this.campaignConfig,
          segments,
        });
      }

      if (status === 'scheduled') {
        this.$notifier.info('Campaign was scheduled successfully');
        await this.$router.push({ name: 'CampaignsSearch' });
      } else {
        this.$notifier.info('Campaign was saved successfully');
      }
    },
    async loadCampaign(id) {
      const campaign = (await this.$rest.campaign.get_resource(id))?.data;
      if (!campaign) {
        return;
      }

      this.name = campaign.name;
      this.type = campaign.type;
      this.campaign = { ...campaign };

      if (campaign.startDate) {
        this.deliveryDate = campaign.startDate.split('T')[0];
        this.deliveryTime = campaign.startDate.split('T')[1].split('-')[0];
      }
      this.timeZone = campaign.timezoneOffset;

      if (!campaign.draftConfiguration.nodes) {
        return;
      }

      this.campaignConfig.segmentId = Object.values(campaign.draftConfiguration.nodes)
        .filter(i => i.nodeType === 'Segment')
        .map(i => i.config.segmentId);

      this.selectedEmailCreative = {};
      if (campaign.type === 'simple') {
        const emailNode = Object.values(campaign.draftConfiguration.nodes).filter(
          i => i.nodeType === 'SendSplitTestEmail' || i.nodeType === 'SendEmail'
        );
        this.campaignConfig.type = emailNode[0].config.type;
        this.campaignConfig.previewText = emailNode[0].config.previewText;
        this.campaignConfig.subject = emailNode[0].config.subject;
        if (emailNode[0].config.fromLineId) {
          this.campaignConfig.fromLineId = emailNode[0].config.fromLineId;
        }
        if (emailNode[0].config.backupFromLineId) {
          this.campaignConfig.backupFromLineId = emailNode[0].config.backupFromLineId;
        }

        // load creative
        const emailCreativeId = emailNode?.[0].config?.creativeId;
        if (!emailCreativeId) {
          return;
        }

        this.selectedEmailCreative = (
          await this.$rest.creative.email.get_resource(emailCreativeId)
        ).data;
      } else if (campaign.type === 'split-test') {
        this.activateSplitTesting = true;
        const emailNode = Object.values(campaign.draftConfiguration.nodes).filter(
          i => i.nodeType === 'SendSplitTestEmail' || i.nodeType === 'SendEmail'
        );
        this.campaignConfig.tests = { ...emailNode[0].config.tests };
        this.campaignConfig.winnerMetric = emailNode[0].config.winnerMetric;
        this.campaignConfig.analysisPhaseDelay = emailNode[0].config.analysisPhaseDelay;
        this.campaignConfig.sendWinningEmail = emailNode[0].config.sendWinningEmail;
        this.campaignConfig.phase = 'test';
        this.campaignConfig.type = emailNode[0].config.type;
        this.campaignConfig.testType = emailNode[0].config.testType;
        this.campaignConfig.testSizePercent = emailNode[0].config.testSizePercent;
        if (emailNode[0].config.fromLineId) {
          this.campaignConfig.fromLineId = emailNode[0].config.fromLineId;
        }
        if (emailNode[0].config.backupFromLineId) {
          this.campaignConfig.backupFromLineId = emailNode[0].config.backupFromLineId;
        }

        // load creative
        if (emailNode[0].config.testType === 'subject') {
          const emailCreativeId = emailNode?.[0].config?.creativeId;
          if (!emailCreativeId) {
            return;
          }
          this.selectedEmailCreative = (
            await this.$rest.creative.email.get_resource(emailCreativeId)
          ).data;
        } else if (emailNode[0].config.testType === 'content') {
          this.campaignConfig.previewText = emailNode[0].config.previewText;
          this.campaignConfig.subject = emailNode[0].config.subject;
          const emailCreativeTestAId = emailNode?.[0].config?.tests?.A?.creativeId;
          const emailCreativeTestBId = emailNode?.[0].config?.tests?.B?.creativeId;
          if (!emailCreativeTestAId || !emailCreativeTestBId) {
            return;
          }

          this.selected_contact_type_key1 = (
            await this.$rest.creative.email.get_resource(emailCreativeTestAId)
          ).data;
          this.selected_contact_type_key2 = (
            await this.$rest.creative.email.get_resource(emailCreativeTestBId)
          ).data;

          if (emailNode[0].config.type === 'ABC') {
            const emailCreativeTestCId = emailNode?.[0].config?.tests?.C?.creativeId;
            if (!emailCreativeTestCId) {
              return;
            }

            this.selected_contact_type_key3 = (
              await this.$rest.creative.email.get_resource(emailCreativeTestCId)
            ).data;
          }
        }
      }
    },
    async loadSegments() {
      this.segments = (
        await this.$rest.segment.get_collection({
          sort: ['name:asc'],
          isArchived: this.campaign.status === "draft" || this.campaign.status === "scheduled" ? false : null,
          ignorePagination: true,
        })
      ).data.items;
    },
    async loadFromLines() {
      this.fromLines = await this.$store.dispatch('automation/getCampaignFromLines');

      if (!this.campaignConfig.fromLineId) {
        const defaultFromLine = this.fromLines.find(i => i.isDefault);

        if (defaultFromLine) {
          this.campaignConfig.fromLineId = defaultFromLine.id;
        }
      }
    },
    async loadEmailCreatives() {
      this.emailCreatives = (
        await this.$rest.creative.email.get_collection({
          isArchived: false,
          htmlContentExist: true,
          sort: ['createdAt:desc'],
          ignorePagination: true,
        })
      ).data.items;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-slider.v-slider--horizontal.theme--light {
    margin: 0 !important;
    .v-slider__track-container {
      height: 9px;
      div:first-child {
        border-top-right-radius: 4.5px !important;
        border-bottom-right-radius: 4.5px !important;
      }
      div:last-child {
        border-top-left-radius: 4.5px !important;
        border-bottom-left-radius: 4.5px !important;
      }
    }
    .v-slider__thumb-container {
      .v-slider__thumb {
        height: 17px;
        width: 17px;
        left: -8px;
        color: transparent;
        background-color: white !important;
        border-color: #dedddc !important;
        border: 1px solid #dedddc;
        cursor: pointer;
      }
    }
    .v-slider__thumb-label-container {
      .v-slider__thumb-label {
        width: 40px !important;
        height: 25px !important;
        transform: none !important;
        background-color: white !important;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
        border-radius: 3px;
        margin-bottom: -40px;
        margin-left: -19px;

        div {
          transform: none !important;
          span {
            color: #2b84eb;
          }
        }
      }
    }
  }
}
</style>
